import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/assets/scss/_custom-vuetify.scss";
import { createVuetify } from "vuetify";

export default createVuetify({
  defaults: {
    VBtn: {
      elevation: 0,
      class: "text-none",
    },
    VCard: {
      class: "vuetify-card",
    },
    VAutocomplete: {
      VChip: {
        color: "primary",
        rounded: "lg",
        variant: "flat",
      },
    },
    VTable: {
      class: "table-component",
    },
    VRangeSlider: {
      class: "vuetify-range-slider",
    },
    VCol: {
      class: "vuetify-col"
    }
  },
  theme: {
    themes: {
      defaultTheme: {
        dark: false,
        colors: {
          background: "#f7f7f7",
          primary: "#3762ea",
          secondary: "#1e1a22",
          light: "#aab2bf",
          info: "#4ab0c1",
          danger: "#ff6c6c",
          success: "#2dcb73",
          warning: "#f6b749",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      defaultThemeDark: {
        dark: true,
        colors: {
          primary: "#3762ea",
          secondary: "#697178",
          light: "#222427",
          info: "#4ab0c1",
          danger: "#ff6c6c",
          success: "#2dcb73",
          warning: "#f6b749",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
          background: "#0f1114",
        },
      },
      material: {
        dark: false,
        colors: {
          primary: "#4f46bb",
          secondary: "#fd912f",
          light: "#eff6ff",
          info: "#74deeb",
          danger: "#f98364",
          warning: "#f9b85a",
          success: "#14cda2",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      materialDark: {
        dark: true,
        colors: {
          primary: "#4f46bb",
          secondary: "#fd912f",
          light: "#222427",
          info: "#74deeb",
          danger: "#f98364",
          warning: "#f9b85a",
          success: "#14cda2",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      creative: {
        dark: false,
        colors: {
          primary: "#0e837c",
          secondary: "#efbf5d",
          light: "#aab2bf",
          info: "#5eb3eb",
          danger: "#ed706f",
          success: "#47c3a6",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      creativeDark: {
        dark: true,
        colors: {
          primary: "#084844",
          secondary: "#efbf5d",
          light: "#222427",
          info: "#5eb3eb",
          danger: "#ed706f",
          success: "#47c3a6",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      minimal: {
        dark: false,
        colors: {
          primary: "#3f7dda",
          secondary: "#5fb1ef",
          light: "#eef1f5",
          info: "#5c8cff",
          danger: "#e15b60",
          success: "#0cba91",
          warning: "#fbbd4a",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      minimalDark: {
        dark: true,
        colors: {
          primary: "#3f7dda",
          secondary: "#5fb1ef",
          light: "#222427",
          info: "#5c8cff",
          danger: "#e15b60",
          success: "#0cba91",
          warning: "#fbbd4a",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      modern: {
        dark: false,

        colors: {
          primary: "#7ac19b",
          secondary: "#f3ce8e",
          light: "#f7f7f7",
          info: "#7dcbf7",
          danger: "#f7a3ae",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      modernDark: {
        dark: true,
        colors: {
          primary: "#7ac19b",
          secondary: "#f3ce8e",
          light: "#222427",
          info: "#7dcbf7",
          danger: "#f7a3ae",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      interaction: {
        dark: false,
        colors: {
          primary: "#54B848",
          secondary: "#0f7cf9",
          light: "#f0f2f7",
          info: "#30e0ec",
          danger: "#f96766",
          warning: "#f9d516",
          success: "#24cd91",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
      interactionDark: {
        dark: true,
        colors: {
          primary: "#14c7dd",
          secondary: "#0f7cf9",
          light: "#222427",
          info: "#30e0ec",
          danger: "#f96766",
          warning: "#f9d516",
          success: "#24cd91",
          "on-primary": "#FFFFFF",
          "on-secondary": "#FFFFFF",
          "on-info": "#FFFFFF",
          "on-success": "#FFFFFF",
          "on-warning": "#FFFFFF",
          "on-danger": "#FFFFFF",
        },
      },
    },
  },
});
